define("discourse/plugins/chat/discourse/models/chat-channel", ["exports", "@glimmer/tracking", "pretty-text/guid", "discourse/lib/utilities", "discourse/models/category", "discourse-common/lib/get-owner", "discourse/plugins/chat/discourse/lib/chat-messages-manager", "discourse/plugins/chat/discourse/lib/chat-threads-manager", "discourse/plugins/chat/discourse/lib/slugify-channel", "discourse/plugins/chat/discourse/models/chat-channel-archive", "discourse/plugins/chat/discourse/models/chat-direct-message", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/models/chat-tracking-state", "discourse/plugins/chat/discourse/models/user-chat-channel-membership"], function (_exports, _tracking, _guid, _utilities, _category, _getOwner, _chatMessagesManager, _chatThreadsManager, _slugifyChannel, _chatChannelArchive, _chatDirectMessage, _chatMessage, _chatTrackingState, _userChatChannelMembership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CHATABLE_TYPES = _exports.CHANNEL_STATUSES = void 0;
  _exports.channelStatusIcon = channelStatusIcon;
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const CHATABLE_TYPES = _exports.CHATABLE_TYPES = {
    directMessageChannel: "DirectMessage",
    categoryChannel: "Category"
  };
  const CHANNEL_STATUSES = _exports.CHANNEL_STATUSES = {
    open: "open",
    readOnly: "read_only",
    closed: "closed",
    archived: "archived"
  };
  function channelStatusIcon(channelStatus) {
    if (channelStatus === CHANNEL_STATUSES.open) {
      return null;
    }
    switch (channelStatus) {
      case CHANNEL_STATUSES.closed:
        return "lock";
      case CHANNEL_STATUSES.readOnly:
        return "comment-slash";
      case CHANNEL_STATUSES.archived:
        return "archive";
    }
  }
  const STAFF_READONLY_STATUSES = [CHANNEL_STATUSES.readOnly, CHANNEL_STATUSES.archived];
  const READONLY_STATUSES = [CHANNEL_STATUSES.closed, CHANNEL_STATUSES.readOnly, CHANNEL_STATUSES.archived];
  let ChatChannel = _exports.default = (_class = class ChatChannel {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatChannel(args);
    }
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      _initializerDefineProperty(this, "title", _descriptor, this);
      _initializerDefineProperty(this, "slug", _descriptor2, this);
      _initializerDefineProperty(this, "description", _descriptor3, this);
      _initializerDefineProperty(this, "status", _descriptor4, this);
      _initializerDefineProperty(this, "activeThread", _descriptor5, this);
      _initializerDefineProperty(this, "meta", _descriptor6, this);
      _initializerDefineProperty(this, "chatableType", _descriptor7, this);
      _initializerDefineProperty(this, "chatableUrl", _descriptor8, this);
      _initializerDefineProperty(this, "autoJoinUsers", _descriptor9, this);
      _initializerDefineProperty(this, "allowChannelWideMentions", _descriptor10, this);
      _initializerDefineProperty(this, "membershipsCount", _descriptor11, this);
      _initializerDefineProperty(this, "archive", _descriptor12, this);
      _initializerDefineProperty(this, "tracking", _descriptor13, this);
      _initializerDefineProperty(this, "threadingEnabled", _descriptor14, this);
      _defineProperty(this, "threadsManager", new _chatThreadsManager.default((0, _getOwner.getOwnerWithFallback)(this)));
      _defineProperty(this, "messagesManager", new _chatMessagesManager.default((0, _getOwner.getOwnerWithFallback)(this)));
      _initializerDefineProperty(this, "_currentUserMembership", _descriptor15, this);
      _initializerDefineProperty(this, "_lastMessage", _descriptor16, this);
      this.id = args.id;
      this.chatableId = args.chatable_id;
      this.chatableUrl = args.chatable_url;
      this.chatableType = args.chatable_type;
      this.membershipsCount = args.memberships_count;
      this.slug = args.slug;
      this.title = args.title;
      this.status = args.status;
      this.description = args.description;
      this.threadingEnabled = args.threading_enabled;
      this.autoJoinUsers = args.auto_join_users;
      this.allowChannelWideMentions = args.allow_channel_wide_mentions;
      this.chatable = this.isDirectMessageChannel ? _chatDirectMessage.default.create({
        id: args.chatable?.id,
        users: args.chatable?.users
      }) : _category.default.create(args.chatable);
      this.currentUserMembership = args.current_user_membership;
      if (args.archive_completed || args.archive_failed) {
        this.archive = _chatChannelArchive.default.create(args);
      }
      this.tracking = new _chatTrackingState.default((0, _getOwner.getOwnerWithFallback)(this));
      this.lastMessage = args.last_message;
      this.meta = args.meta;
    }
    get unreadThreadsCountSinceLastViewed() {
      return Array.from(this.threadsManager.unreadThreadOverview.values()).filter(lastReplyCreatedAt => lastReplyCreatedAt >= this.currentUserMembership.lastViewedAt).length;
    }
    updateLastViewedAt() {
      this.currentUserMembership.lastViewedAt = new Date();
    }
    get canDeleteSelf() {
      return this.meta.can_delete_self;
    }
    get canDeleteOthers() {
      return this.meta.can_delete_others;
    }
    get canFlag() {
      return this.meta.can_flag;
    }
    get userSilenced() {
      return this.meta.user_silenced;
    }
    get canModerate() {
      return this.meta.can_moderate;
    }
    get escapedTitle() {
      return (0, _utilities.escapeExpression)(this.title);
    }
    get escapedDescription() {
      return (0, _utilities.escapeExpression)(this.description);
    }
    get slugifiedTitle() {
      return this.slug || (0, _slugifyChannel.default)(this);
    }
    get routeModels() {
      return [this.slugifiedTitle, this.id];
    }
    get isDirectMessageChannel() {
      return this.chatableType === CHATABLE_TYPES.directMessageChannel;
    }
    get isCategoryChannel() {
      return this.chatableType === CHATABLE_TYPES.categoryChannel;
    }
    get isOpen() {
      return !this.status || this.status === CHANNEL_STATUSES.open;
    }
    get isReadOnly() {
      return this.status === CHANNEL_STATUSES.readOnly;
    }
    get isClosed() {
      return this.status === CHANNEL_STATUSES.closed;
    }
    get isArchived() {
      return this.status === CHANNEL_STATUSES.archived;
    }
    get isJoinable() {
      return this.isOpen && !this.isArchived;
    }
    get isFollowing() {
      return this.currentUserMembership.following;
    }
    get canJoin() {
      return this.meta.can_join_chat_channel;
    }
    async stageMessage(message) {
      message.id = (0, _guid.default)();
      message.staged = true;
      message.processed = false;
      message.draft = false;
      message.createdAt = new Date();
      message.channel = this;
      if (message.inReplyTo) {
        if (!this.threadingEnabled) {
          this.messagesManager.addMessages([message]);
        }
      } else {
        this.messagesManager.addMessages([message]);
      }
      message.manager = this.messagesManager;
    }
    canModifyMessages(user) {
      if (user.staff) {
        return !STAFF_READONLY_STATUSES.includes(this.status);
      }
      return !READONLY_STATUSES.includes(this.status);
    }
    get currentUserMembership() {
      return this._currentUserMembership;
    }
    set currentUserMembership(membership) {
      if (membership instanceof _userChatChannelMembership.default) {
        this._currentUserMembership = membership;
      } else {
        this._currentUserMembership = _userChatChannelMembership.default.create(membership);
      }
    }
    get lastMessage() {
      return this._lastMessage;
    }
    set lastMessage(message) {
      if (!message) {
        this._lastMessage = null;
        return;
      }
      if (message instanceof _chatMessage.default) {
        this._lastMessage = message;
      } else {
        this._lastMessage = _chatMessage.default.create(this, message);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slug", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "status", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "activeThread", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "meta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "chatableType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "chatableUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "autoJoinUsers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "allowChannelWideMentions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "membershipsCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "archive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "tracking", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "threadingEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_currentUserMembership", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "_lastMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});